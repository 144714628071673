import axios from 'axios';

// Base URL for the API
const BASE_URL = 'https://codeagency.in/pro1/admin/';
var block = [];
var updatedblock = [];
// Function to fetch gallery images filtered by block_name
export const fetchGalleryImages = async (blockName) => {
    try {
        const response = await axios.get(`${BASE_URL}api/block_api.php`);
        console.log('Full API response:', response.data); // Log the full API response

        // Find the block with the matching block_name

        let blockNames = blockName.split(",");
        if (blockNames.length > 1) {
            blockNames.forEach(
                (item, index) => {
                    block = response.data.gallery.filter(item2 => {
                        if (item === item2.block_name) {
                            updatedblock.push(item2);
                        }
                    })
                }
            )

            block = updatedblock.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    JSON.stringify(t) === JSON.stringify(value)
                ))
            );

            console.log("blocki", block);


        } else {
            block = response.data.gallery.find(
                (item) => item.block_name === blockName
            );
        }



        if (!block) {
            console.warn(`No data found for block_name: ${blockName}`);
            return [];
        }

        console.log('Block data:', block); // Log the block to check other data

        // Parse the images JSON string into an array if images exist
        const parsedImages = block.images ? JSON.parse(block.images) : [];

        // Clean up the image paths and append the base URL
        const updatedImageArray = parsedImages.map((imagePath) => {
            const cleanPath = imagePath.replace(/^..\/+/, ''); // Clean up "../"
            return `${BASE_URL}${cleanPath}`;
        });

        // Return all the block data along with the updated images array
        if (blockNames.length > 1) {
            return block;
        } else {
            return {
                ...block, // Return all the block data (e.g., title, subtitle, description, etc.)
                images: updatedImageArray // Add images after processing
            };
        }

    } catch (error) {
        console.error(`Error fetching gallery data for block_name "${blockName}":`, error);
        throw error; // Optionally throw the error to handle it in the component
    }
};
