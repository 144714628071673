import React, { useState, useEffect } from 'react';
import { fetchGalleryImages } from '../../services/apiService'; // Adjust path as needed
import styles from './TwoSectionSliderLeft.module.css';

const TwoSectionSliderLeft = ({ blockName }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [blockData, setBlockData] = useState(null); // State to store block data
    const [slides, setSlides] = useState([]); // State to store image URLs

    useEffect(() => {
        const loadBlockData = async () => {
            try {
                const data = await fetchGalleryImages(blockName); // Pass block name to fetch data
                console.log('Block Data1:', data);
                setBlockData(data); // Set block data (title, subtitle, etc.)
                if (data?.images) {
                    setSlides(data.images); // Assuming the response contains an "images" field
                }
            } catch (error) {
                console.error('Error loading block data:', error);
            }
        };

        loadBlockData();
    }, [blockName]);

    // Function to go to the next slide
    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    // Function to go to the previous slide
    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
    };

    // Function to clean unwanted escape sequences and excessive backslashes
    const cleanString = (str) => {
        if (!str) return '';
        return str.replace(/\\+/g, '\\').replace(/\\'/g, "'").replace(/\\"/g, '"');
    };

    return (
        <div className={styles.twoSectionSliderContainer}>
            {/* Slider Section */}
            {slides.length > 0 && (
                <div className={styles.slider}>
                    <div className={styles.sliderContent} style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                        {slides.map((slide, index) => (
                            <div key={index} className={styles.slide}>
                                <img src={slide} alt={`Slide ${index + 1}`} /> {/* Directly using slide as the image URL */}
                            </div>
                        ))}
                    </div>

                    {/* Left and Right Arrows - Only show when there is more than 1 image */}
                    {slides.length > 1 && (
                        <>
                            <button className={`${styles.arrow} ${styles.arrowLeft}`} onClick={prevSlide}>
                                ←
                            </button>
                            <button className={`${styles.arrow} ${styles.arrowRight}`} onClick={nextSlide}>
                                →
                            </button>
                        </>
                    )}
                </div>
            )}

            {/* Content Section */}
            {blockData && (
                <div className={styles.content}>
                    <h3 className={styles.subtitle}>{cleanString(blockData?.sub_title)}</h3> {/* Clean sub_title */}
                    <h2 className={styles.title}>{cleanString(blockData?.title)}</h2> {/* Clean title */}
                    <p
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: cleanString(blockData?.disc) }} // Clean and render description as HTML
                    />

                    {/* Conditionally render the buttons */}
                    {blockData?.btn1 && blockData?.btn1_link && (
                        <a
                            href={blockData.btn1_link}
                            className={styles.reserveButton}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {blockData.btn1}
                        </a>
                    )}

                    {blockData?.btn2 && blockData?.btn2_link && (
                        <a
                            href={blockData.btn2_link}
                            className={styles.reserveButton}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {blockData.btn2}
                        </a>
                    )}
                </div>
            )}
        </div>
    );
};

export default TwoSectionSliderLeft;
