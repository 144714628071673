import React, { useState, useEffect } from 'react';
import { fetchGalleryImages } from '../../services/apiService'; // Adjust the path as needed
import styles from './GalleryGrid.module.css';

const GalleryGrid = ({ blockName }) => {
    const [slides, setSlides] = useState([]);

    useEffect(() => {
        const loadSlides = async () => {
            try {
                const images = await fetchGalleryImages(blockName); // Pass block name to fetch data
                setSlides(images?.images || []); // Set images
            } catch (error) {
                console.error('Error loading images:', error);
            }
        };

        loadSlides();
    }, [blockName]);

    return (
        <div className={styles.gridContainer}>
            {slides.length > 0 ? (
                slides.map((slide, index) => (
                    <div key={index} className={styles.gridItem}>
                        <img src={slide} alt={`Slide ${index + 1}`} className={styles.gridImage} />
                    </div>
                ))
            ) : (
                <p>No images found.</p>
            )}
        </div>
    );
};

export default GalleryGrid;
