import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import styles from './MenuHeader.module.css';

function MenuHeader() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuItems, setMenuItems] = useState([]); // State to store fetched menu items

    // Function to toggle the menu
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Fetch menu data from the API
    useEffect(() => {
        // Replace with your API endpoint
        axios.get('https://codeagency.in/pro1/admin/api/menus_api.php')
            .then((response) => {
                console.log(response);
                setMenuItems(response.data.menus); // Set the fetched menu items
            })
            .catch((error) => {
                console.error("There was an error fetching the menu data:", error);
            });
    }, []); // Empty dependency array to run only once when the component mounts

    return (
        <header className={styles.header}>
            <div className={styles.logo}>
                <img src="https://codeagency.in/pro1/assets/images/logo.jpg" alt="Logo" className={styles.logoImage} />
            </div>

            {/* Hamburger Menu Icon */}
            <div className={styles.hamburger} onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>

            {/* Menu with toggle class based on isMenuOpen */}
            <nav className={`${styles.menu} ${isMenuOpen ? styles.open : ''}`}>
                {/* Render fetched menu items dynamically */}
                {menuItems.map((item, index) => (
                    // Use Link to navigate to internal routes without refreshing the page
                    <Link to={item.menu_link} key={index} className={styles.menuItem}>
                        {item.menu_name}
                    </Link>
                ))}
            </nav>

            <div className={styles.buttonContainer}>
                {/* Reserve Button navigates to /reserve */}
                <Link to="/reserve" className={styles.button}>
                    Reserve Now
                </Link>
            </div>
        </header>
    );
}

export default MenuHeader;
