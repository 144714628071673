import React, { useState, useEffect } from 'react';
import { fetchGalleryImages } from '../../services/apiService'; // Import API service
import styles from './ThreeBox.module.css';

const ThreeBox = ({ blockName }) => {
    const [blockData, setBlockData] = useState([]); // State to store block data
    const [loading, setLoading] = useState(true); // State to track loading

    useEffect(() => {
        const loadBlockData = async () => {
            try {
                const data = await fetchGalleryImages(blockName); // Fetch data with blockName
                setBlockData(data || []); // Store the block data
            } catch (error) {
                console.error('Error fetching homepage data:', error);
            } finally {
                setLoading(false);
            }
        };

        loadBlockData();
    }, [blockName]); // Refetch data when blockName changes

    // Function to clean unwanted escape sequences and excessive backslashes
    const cleanString = (str) => {
        if (!str) return '';
        return str.replace(/\\+/g, '\\').replace(/\\'/g, "'").replace(/\\"/g, '"');
    };

    return (
        <div className={styles.container}>
            {loading ? (
                <p>Loading content...</p>
            ) : blockData && blockData.length > 0 ? (
                <div className={styles.boxContainer}>
                    {/* Loop through blockData and render each box */}
                    {blockData.map((data, index) => (
                        <div key={index} className={styles.box}>
                            {/* Render Image */}
                            {data.images?.[0] && (
                                <img
                                    src={
                                        'https://codeagency.in/pro1/admin' +
                                        JSON.parse(data.images.replace(/..\/+/g, '/').replace(/(\/\.\.\/|\.\/)/g, '/'))[0].replace('/image/', '/images/')
                                    } // Render the first image
                                    alt={cleanString(data.title)} // Use the cleaned title as alt text
                                    className={styles.image}
                                />
                            )}

                            {/* Render Content */}
                            <div className={styles.textBox}>
                                {data.sub_title && <h3 className={styles.subtitle}>{cleanString(data.sub_title)}</h3>} {/* Clean sub_title */}
                                <h3 className={styles.title}>{cleanString(data.title)}</h3> {/* Clean title */}
                                <p
                                    className={styles.description}
                                    dangerouslySetInnerHTML={{ __html: cleanString(data.disc) }} // Clean and render description as HTML
                                />

                                {/* Render Buttons */}
                                {data.btn1 && data.btn1_link && (
                                    <a
                                        href={data.btn1_link}
                                        className={styles.learnMoreButton}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {data.btn1}
                                    </a>
                                )}
                                {data.btn2 && data.btn2_link && (
                                    <a
                                        href={data.btn2_link}
                                        className={styles.learnMoreButton}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {data.btn2}
                                    </a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No content available for this block.</p> // Fallback for no data
            )}
        </div>
    );
};

export default ThreeBox;
