import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/home';
import About from './pages/about';
import Event from './pages/event';
import Gallery from './pages/gallery';
import MenuHeader from './components/Header/MenuHeader';
import Menu from './pages/menu';
function App() {
  return (
    <Router>
      <Header />
      <MenuHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/events" element={<Event />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/menu" element={<Menu />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
