import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './EventBlock.module.css'; // Add your CSS styles for layout

// Function to clean backslashes and decode HTML entities
const cleanString = (str) => {
    if (!str) return '';

    // Remove unnecessary backslashes
    let cleanedStr = str.replace(/\\+/g, '');

    // Decode HTML entities (like &nbsp;, &lt;, &gt;, etc.)
    const doc = new DOMParser().parseFromString(cleanedStr, 'text/html');
    cleanedStr = doc.body.textContent || ''; // Get decoded string

    return cleanedStr;
};

const EventBlock = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('https://codeagency.in/pro1/admin/api/event_api.php');
                setEvents(response.data.events); // Assuming the response data contains the events
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    return (
        <div className={styles.eventContainer}>
            {events?.length > 0 ? (
                events.map((event) => (
                    <div key={event.e_id} className={styles.eventBlock}>
                        {/* Left Block for Image */}
                        <div className={styles.imageBlock}>
                            <img
                                src={"https://codeagency.in/pro1/admin/" + event.event_featured.replace("../images", "images")} // Assuming event_banner contains image URL
                                alt={cleanString(event.event_title)} // Clean the title for safe rendering
                                className={styles.eventImage}
                            />
                        </div>

                        {/* Right Block for Title and Description */}
                        <div className={styles.textBlock}>
                            <h2 className={styles.title}>{cleanString(event.event_title)}</h2> {/* Clean the event title */}
                            <p className={styles.description}>{cleanString(event.event_disc)}</p> {/* Clean the event description */}
                            <div className={styles.buttons}>
                                {event.event_btn1 && event.event_btn1_link && (
                                    <a
                                        href={event.event_btn1_link}
                                        className={styles.button}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {cleanString(event.event_btn1)} {/* Clean button text */}
                                    </a>
                                )}
                                {event.event_btn2 && event.event_btn2_link && (
                                    <a
                                        href={event.event_btn2_link}
                                        className={styles.button}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {cleanString(event.event_btn2)} {/* Clean button text */}
                                    </a>
                                )}
                                {event.event_btn3 && event.event_btn3_link && (
                                    <a
                                        href={event.event_btn3_link}
                                        className={styles.button}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {cleanString(event.event_btn3)} {/* Clean button text */}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>No events found.</p>
            )}
        </div>
    );
};

export default EventBlock;
