import React from 'react';
import GalleryGrid from '../components/Main/Blocks/GalleryGrid';
const Gallery = () => {
    return (
        <>
            <GalleryGrid blockName="gallery-grid-block-1" />
        </>
    );
};

export default Gallery;
