import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios
import styles from './Header.module.css';

function Header() {
    const [socialIcons, setSocialIcons] = useState([]); // State to store social icons data

    // Fetch social icons data from the API
    useEffect(() => {
        axios.get('https://codeagency.in/pro1/admin/api/social_api.php')
            .then((response) => {
                console.log("response1", response.data.social_icons);
                if (response.data.status === 'success') {
                    setSocialIcons(response.data.social_icons); // Store fetched social icons
                }
            })
            .catch((error) => {
                console.error('Error fetching social icons:', error);
            });

        // Dynamically add Font Awesome CDN link
        const fontAwesomeScript = document.createElement('link');
        fontAwesomeScript.rel = 'stylesheet';
        fontAwesomeScript.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css';
        document.head.appendChild(fontAwesomeScript);

        // Clean up: remove the CDN link when the component unmounts
        return () => {
            document.head.removeChild(fontAwesomeScript);
        };
    }, []); // Empty dependency array to run only once when component mounts

    return (
        <header className={styles.header}>
            <div></div>
            <div className={styles.icons}>
                {socialIcons.map((icon) => (
                    <a
                        key={icon.s_id} // Using s_id as key
                        href={icon.social_link} // Link from API
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.iconLink}
                    >
                        <i className={`${icon.social_icon} ${styles.icons}`} />
                    </a>
                ))}
            </div>
        </header>
    );
}

export default Header;
