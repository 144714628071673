import React, { useState, useEffect } from 'react';
import { fetchGalleryImages } from '../../services/apiService'; // Adjust the path as needed
import styles from './SingleBox.module.css';

const SingleBox = ({ blockName }) => {
    const [blockData, setBlockData] = useState(null); // State to store block data
    const [images, setImages] = useState([]); // State to store images

    useEffect(() => {
        const loadBlockData = async () => {
            try {
                const data = await fetchGalleryImages(blockName); // Pass "home-single-block-1" as block_name
                console.log("data", data);
                setBlockData(data); // Set block data
                if (data?.images) {
                    setImages(data.images); // Assuming the response contains an images field
                }
            } catch (error) {
                console.error('Error loading block data:', error);
            }
        };

        loadBlockData();
    }, [blockName]);

    // Function to clean unwanted escape sequences and excessive backslashes
    const cleanString = (str) => {
        if (!str) return '';
        // Clean all extra backslashes and replace with the actual character
        return str.replace(/\\+/g, '\\').replace(/\\'/g, "'").replace(/\\"/g, '"');
    };

    // Clean title, description, and sub_title
    const cleanTitle = cleanString(blockData?.title);
    const cleanDescription = cleanString(blockData?.disc);
    const cleanSubTitle = cleanString(blockData?.sub_title);  // Clean sub_title here as well

    return (
        <div className={styles.whiteBox}>
            <h2 className={styles.subtitle}>{cleanSubTitle}</h2>  {/* Use cleaned sub_title */}
            <h1 className={styles.title}>{cleanTitle}</h1>
            <p className={styles.description} dangerouslySetInnerHTML={{ __html: cleanDescription }} />

            {/* Conditionally render the buttons */}
            {blockData?.btn1 && blockData?.btn1_link && (
                <a href={blockData.btn1_link} className={styles.reserveButton} target="_blank" rel="noopener noreferrer">
                    {blockData.btn1}
                </a>
            )}

            {blockData?.btn2 && blockData?.btn2_link && (
                <a href={blockData.btn2_link} className={styles.reserveButton} target="_blank" rel="noopener noreferrer">
                    {blockData.btn2}
                </a>
            )}
        </div>
    );
};

export default SingleBox;
