import React from 'react';
import SingleBox from '../components/Main/Blocks/SingleBox';
import EventBlock from '../components/Main/Blocks/EventBlock';

const Event = () => {
    return (
        <>
            <SingleBox blockName="events-block-1" />
            <EventBlock />
        </>
    );
};

export default Event;
