import React from 'react';
import FullScreenSlider from '../components/Main/Blocks/FullScreenSlider';
import SingleBox from '../components/Main/Blocks/SingleBox';
import TwoSectionSliderRight from '../components/Main/Blocks/TwoSectionSliderRight';

const Reserve = () => {
    return (
        <>
            <FullScreenSlider blockName="about-us-block-1" />
            <SingleBox blockName="about-us-block-2" />
            <SingleBox blockName="about-us-block-3" />
            <TwoSectionSliderRight blockName="about-us-block-4" />

        </>
    );
};

export default Reserve;
