import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SingleBox from '../components/Main/Blocks/SingleBox';
import FullScreenSlider from '../components/Main/Blocks/FullScreenSlider';
import styles from './menu.module.css'; // Make sure you have styles for grid layout

const Menu = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://codeagency.in/pro1/admin/api/product_api.php');
                setProducts(response.data.products); // Assuming the response contains the products
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    // Group products by category (cat_title)
    const groupedProducts = products.reduce((acc, product) => {
        const { cat_title } = product;
        if (!acc[cat_title]) {
            acc[cat_title] = [];
        }
        acc[cat_title].push(product);
        return acc;
    }, {});

    return (
        <>
            <FullScreenSlider blockName="menu-block-1" />
            <SingleBox blockName="menu-block-2" />

            {/* Grid Container for Products */}
            <div className={styles.productGrid}>
                {Object.keys(groupedProducts).map((category, index) => (
                    <div key={index} className={styles.productCategory}>
                        {/* Category Title and Horizontal Line */}
                        <h2>{category}</h2>
                        <hr />

                        {/* Loop through products in the current category */}
                        <div className={styles.productDetails}>
                            {groupedProducts[category].map((product) => (
                                <div key={product.p_id} className={styles.productItem}>
                                    <div className={styles.leftSection}>
                                        <h3>{product.p_title}</h3>
                                        {/* Keep HTML entities in description */}
                                        <p dangerouslySetInnerHTML={{ __html: product.p_disc }} />
                                    </div>
                                    <div className={styles.rightSection}>
                                        {/* Keep HTML entities in price */}
                                        <div
                                            className={styles.price}
                                            dangerouslySetInnerHTML={{
                                                __html: product.p_price,
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Menu;
