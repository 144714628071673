import React, { useState, useEffect } from 'react';
import axios from 'axios';  // Import axios for making API requests
import styles from './Footer.module.css';

function Footer() {
    const [footerData, setFooterData] = useState(null);  // State to hold footer data
    const [socialIcons, setSocialIcons] = useState([]);  // State to store social icons data
    const [showScrollButton, setShowScrollButton] = useState(false);  // Initially hide the button

    // Fetch footer data using axios
    useEffect(() => {
        // Fetch footer data
        axios.get('https://codeagency.in/pro1/admin/api/footer_api.php')
            .then(response => {
                if (response.data.status === 'success') {
                    setFooterData(response.data.footer);  // Set the fetched footer data
                }
            })
            .catch(error => {
                console.error('Error fetching footer data:', error);
            });

        // Fetch social icons data
        axios.get('https://codeagency.in/pro1/admin/api/social_api.php')
            .then((response) => {
                if (response.data.status === 'success') {
                    setSocialIcons(response.data.social_icons);  // Store fetched social icons
                }
            })
            .catch((error) => {
                console.error('Error fetching social icons:', error);
            });

        // Handle scroll event to toggle the button visibility
        const handleScroll = () => {
            // Check if the user is at the bottom of the page
            const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1;
            setShowScrollButton(isAtBottom);
        };

        // Add the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Initial check to handle the button state if already at the bottom
        handleScroll();

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);  // Empty dependency array to run only once when the component mounts

    // Function to scroll to the top
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // If footer data is not loaded yet, show loading text
    if (!footerData) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <div className={styles.mainContainer}>
                <div className={styles.topSection}>
                    {/* Left Box: Subscribe Section */}
                    <div className={styles.subscribeBox}>
                        <h3 className={styles.subscribeTitle}>{footerData.f_newsletter_title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: footerData.f_newsletter_subtitle }} />
                        <div className={styles.subscribeInputWrapper}>
                            <input
                                type="email"
                                className={styles.emailInput}
                                placeholder="Enter your email"
                            />
                            <button className={styles.subscribeButton}>Subscribe</button>
                        </div>
                    </div>

                    {/* Right Box: Logos Section */}
                    <div className={styles.logosBox}>
                        {footerData.f_logos.map((logo, index) => (
                            <div className={styles.logo} key={index}>
                                <img src={"https://codeagency.in/pro1/admin/" + logo.replace("../images/", "/images/")} alt={`Logo ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.bottomSection}>
                    {/* Left Box: Paragraph and List Section */}
                    <div className={styles.firstColumn}>
                        <div className={`${styles.row} ${styles.row1}`}>
                            <div dangerouslySetInnerHTML={{ __html: footerData.f_food }} />
                        </div>
                    </div>

                    {/* Right Box: Social & Contact Info */}
                    <div className={styles.secondColumn}>
                        <div className={styles.socialIcons}>
                            <h3><strong>SOCIAL MEDIA</strong></h3>
                            {socialIcons.map((icon) => (
                                <a
                                    key={icon.s_id}  // Using s_id as key
                                    href={icon.social_link}  // Link from API
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.iconLink}
                                >
                                    <i className={`${icon.social_icon} ${styles.icons}`} />
                                </a>
                            ))}
                        </div>

                        <div className={styles.contactInfo}>
                            <h3><strong>CONTACT US</strong></h3>
                            <div dangerouslySetInnerHTML={{ __html: footerData.f_contact }} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Bottom Footer Section */}
            <div className={styles.bottomFooter}>
                <div className={styles.copyright}>
                    <div dangerouslySetInnerHTML={{ __html: footerData.f_copyright }} />
                </div>
            </div>

            {/* Conditional Scroll to Top Button */}
            {showScrollButton && (
                <button className={styles.scrollTopButton} onClick={scrollToTop}>
                    Scroll to top ↑
                </button>
            )}
        </>
    );
}

export default Footer;
