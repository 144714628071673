import React, { useState, useEffect } from 'react';
import { fetchGalleryImages } from '../../services/apiService'; // Adjust the path as needed
import styles from './FullScreenSlider.module.css';

const FullScreenSlider = ({ blockName }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slides, setSlides] = useState([]);

    // Fetch images when component mounts
    useEffect(() => {
        const loadSlides = async () => {
            try {
                const images = await fetchGalleryImages(blockName); // Pass "home-slider" as the block name
                setSlides(images?.images || []); // Ensure `slides` is always an array
            } catch (error) {
                console.error('Error loading slides:', error);
            }
        };

        loadSlides();
    }, [blockName]); // Include `blockName` in dependency array to re-fetch if it changes

    // Auto-scroll functionality
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, 5000); // Change slide every 1000 ms (1 second)

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, [slides]); // Only restart interval when slides change

    // Function to go to a specific slide when a nav dot is clicked
    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    return (
        <div className={styles.sliderContainer}>
            <div className={styles.sliderContent}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`${styles.slide} ${currentSlide === index ? styles.active : ''}`}
                    >
                        <img src={slide} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </div>

            {/* Conditionally render nav dots only if there are multiple slides */}
            {slides.length > 1 && (
                <div className={styles.navDots}>
                    {slides.map((_, index) => (
                        <span
                            key={index}
                            className={`${styles.navDot} ${currentSlide === index ? styles.activeDot : ''}`}
                            onClick={() => goToSlide(index)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default FullScreenSlider;
