import React from 'react';
import FullScreenSlider from '../components/Main/Blocks/FullScreenSlider';
import SingleBox from '../components/Main/Blocks/SingleBox';
import TwoSectionSlider from '../components/Main/Blocks/TwoSectionSliderLeft';
import TwoSlider2 from '../components/Main/Blocks/TwoSectionSliderRight';
import ThreeBox from '../components/Main/Blocks/ThreeBox';

function Home() {
    return (
        <div>
            <FullScreenSlider blockName="home-slider" />
            <SingleBox blockName="home-single-block-1" />
            <TwoSectionSlider blockName="home-multi-block-1" />
            <TwoSlider2 blockName="home-multi-block-2" />
            <SingleBox blockName="home-single-block-2" />
            <ThreeBox blockName="verticle-three-block-1,verticle-three-block-2,verticle-three-block-3" />
        </div>
    );
}

export default Home;
